import { Bar } from 'react-chartjs-2';
import { Chart, BarController, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import _ from 'lodash';

import { QCard } from '../../components/QCard/QCard';
import { QCardHeader } from '../../components/QCard/QCardHeader';
import { QCardContent } from '../../components/QCard/QCardContent';
import { QTypography } from '../../components/QTypography/QTypography';

interface RuleData {
  risk: string;
  ruleId: string;
}

interface ProfileCategoriesData {
  results: RuleData[];
}

const formatLabel = (label, labelMapping) =>
  labelMapping[label] ||
  label
    .split('-')
    .map(part => part.charAt(0).toUpperCase() + part.slice(1))
    .join('-');

export const getRiskCounts = results => {
  try {
    const labels = _.uniq(results.map(result => result.ruleId.split('/')[1]));

    const riskCounts = {
      warning: Array(labels.length).fill(0),
      note: Array(labels.length).fill(0),
      error: Array(labels.length).fill(0),
      labels,
    };

    results.forEach(result => {
      const label = result.ruleId.split('/')[1];
      const labelIndex = riskCounts.labels.indexOf(label);

      if (labelIndex !== -1) {
        if (result.risk in riskCounts) {
          // eslint-disable-next-line no-plusplus
          riskCounts[result.risk][labelIndex]++;
        }
      }
    });

    return riskCounts;
  } catch (error) {
    console.error('Error getting risk counts', error);
    return {
      warning: [],
      note: [],
      error: [],
      labels: [],
    };
  }
};

export const ProfileCategoriesBarChart = ({ results }: ProfileCategoriesData) => {
  Chart.register(BarController, BarElement, CategoryScale, LinearScale, Tooltip, Legend);

  const BORDER_RADIUS = 15;
  const BAR_WIDTH_PERCENTAGE = 0.3;
  const ERROR_COLOR = '#F44336';
  const WARNING_COLOR = '#FF9800';
  const NOTE_COLOR = '#2196F3';

  const threatTypeCategoriesMapping = {
    authentication: 'Authentication',
    persistence: 'Persistence',
    components: 'Components',
    system: 'System',
    'dynamic-code': 'Dynamic-Code',
    sqlite: 'SQLite',
    io: 'IO',
    base64: 'Base64',
    connectivity: 'Connectivity',
    net: 'Network',
    pii: 'PII',
    crypto: 'Cryptography',
    webkit: 'WebKit',
  };

  const riskCounts = getRiskCounts(results);

  const data = {
    labels: riskCounts.labels,
    datasets: [
      {
        label: 'Error',
        data: riskCounts.error,
        backgroundColor: ERROR_COLOR,
        borderColor: ERROR_COLOR,
        hoverBackgroundColor: ERROR_COLOR,
        borderRadius: BORDER_RADIUS,
        barPercentage: BAR_WIDTH_PERCENTAGE,
      },
      {
        label: 'Warning',
        data: riskCounts.warning,
        backgroundColor: WARNING_COLOR,
        borderColor: WARNING_COLOR,
        hoverBackgroundColor: WARNING_COLOR,
        borderRadius: BORDER_RADIUS,
        barPercentage: BAR_WIDTH_PERCENTAGE,
      },
      {
        label: 'Note',
        data: riskCounts.note,
        backgroundColor: NOTE_COLOR,
        borderColor: NOTE_COLOR,
        hoverBackgroundColor: NOTE_COLOR,
        borderRadius: BORDER_RADIUS,
        barPercentage: BAR_WIDTH_PERCENTAGE,
      },
    ],
  };

  // // Calculate the maximum value across all datasets
  const maxDataValue = Math.max(...data.datasets.flatMap(dataset => dataset.data));

  // // Determine the step size based on the maximum data value
  const determineStepSize = maxValue => {
    if (maxValue <= 20) return 5;
    if (maxValue <= 50) return 10;
    if (maxValue <= 100) return 25;
    return 50;
  };

  const stepSize = determineStepSize(maxDataValue);

  const barProps = {
    data,
    options: {
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            display: false,
          },
          stacked: true,
          ticks: {
            callback(value, index) {
              const label = data.labels[index];

              return formatLabel(label, threatTypeCategoriesMapping);
            },
            autoSkip: false,
            font: {
              size: '14vw',
              weight: 'bold',
            },
          },
        },
        y: {
          ticks: {
            stepSize,
          },
          stacked: true,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    },
  };

  return (
    <QCard style={{ height: '100%' }}>
      <QCardHeader title={<QTypography variant="h5Medium">Categories</QTypography>} />
      <QCardContent
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '350px',
        }}
      >
        <Bar {...(barProps as any)} />
      </QCardContent>
    </QCard>
  );
};
