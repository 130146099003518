import { Chart, ArcElement, Tooltip, Legend, DoughnutController } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import _ from 'lodash';
import PropTypes from 'prop-types';

export const QDoughnut = props => {
  Chart.register(ArcElement, Tooltip, Legend, DoughnutController);

  const centerTextPlugin = {
    id: 'centerTextPlugin',
    beforeDraw(chart) {
      if (chart.config.type === 'doughnut' && _.get(chart, 'data.datasets[0].data')) {
        const { ctx, chartArea } = chart;
        const { controller } = chart.getDatasetMeta(0);

        // Get options from the center object in options
        const totalCount = _.sum(chart.data.datasets[0].data);
        const definedValue = props.value !== undefined && props.value !== null;
        const displayValue = definedValue ? props.value : totalCount;
        const isNoData = displayValue === -1;

        // Adjust the font size conditionally
        let numberFontSize;
        let titleFontSize;
        let centerYAdjustment;

        if (isNoData || definedValue) {
          numberFontSize = 2.5;
          centerYAdjustment = 1.3;
          ctx.font = `700 ${numberFontSize}em 'Nunito Sans'`;
        } else {
          numberFontSize =
            (Math.min(Number((chart.height / 60).toFixed(2)), controller.innerRadius / 25) * 3) / Math.max(String(displayValue).length, 2);
          titleFontSize = Math.min(Number((chart.height / 150).toFixed(2)), controller.innerRadius / 50);
          centerYAdjustment = 2; // Default adjustment for TOTAL text
          ctx.font = `600 ${numberFontSize}em 'Nunito Sans'`; // Regular font for total
        }

        const lineHeight = titleFontSize * 10;

        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        const centerX = (chartArea.left + chartArea.right) / 2;
        let centerY = (chartArea.top + chartArea.bottom) / centerYAdjustment;

        ctx.fillStyle = '#000';

        // Display "N/A" if value is -1
        if (isNoData) {
          ctx.fillText('N/A', centerX, centerY);
        } else {
          ctx.fillText(displayValue, centerX, centerY);

          // Move down and add subtext in smaller font if not definedValue
          if (!definedValue) {
            ctx.font = `600 ${titleFontSize}em 'Nunito Sans'`;
            centerY += lineHeight * 3; // Move down the TOTAL text
            ctx.fillText('TOTAL', centerX, centerY);
          }
        }
      }
    },
  };

  const passedLegend = props?.options?.legend;

  const parsedLegend = {
    ...passedLegend,
    display: !props.noLegend,
    position: 'left',
    labels: {
      ...passedLegend?.labels,
      usePointStyle: true,
      pointStyle: 'circle',
      color: '#000000',
      font: {
        size: 14,
        style: 'normal',
        family: 'Nunito Sans',
        weight: 500,
        lineHeight: 19.1,
        letterSpacing: 0,
      },
      generateLabels: chart => {
        const { datasets } = chart.data;

        return datasets[0].data.map((data, i) => ({
          text: `${chart.data.labels[i].length > 20 ? `${chart.data.labels[i].slice(0, 15)} ...` : chart.data.labels[i]} ${
            props.hideNumbers ? '' : `(${data})`
          }`,
          fillStyle: datasets[0].backgroundColor[i],
          hidden: !chart.getDataVisibility(i),
          index: i,
        }));
      },
    },
  };

  // If no options are passed we still want to force the legend styling for our design.
  props = {
    ...props,
    options: {
      ...props?.options,
      ...(props?.options?.maintainAspectRatio ?? { aspectRatio: 1.5, maintainAspectRatio: false }),

      plugins: {
        ...props?.options?.plugins,
        legend: parsedLegend,
      },
    },
    plugins: [centerTextPlugin],
  };

  return <Doughnut {...props} />;
};

QDoughnut.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object,
  value: PropTypes.any,
  noLegend: PropTypes.bool,
  hideNumbers: PropTypes.bool,
};
