import { useTheme } from '@mui/material';
import { ChartData, ChartOptions } from 'chart.js';
import { QCard } from '../QCard/QCard';
import { QCardHeader } from '../QCard/QCardHeader';
import { QCardContent } from '../QCard/QCardContent';
import { QDoughnut } from '../QDoughnut/QDoughnut';

export const QDoughnutCard = ({ title, data, options }: { title: string; data: ChartData; options: ChartOptions }) => {
  const theme = useTheme();

  return (
    <QCard sx={{ height: '100%' }}>
      <QCardHeader
        title={title}
        sx={{
          '& .MuiTypography-root': {
            color: theme.palette.text.primary,
            fontSize: '18px',
          },
        }}
      />
      <QCardContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <QDoughnut data={data} options={options} />
      </QCardContent>
    </QCard>
  );
};
