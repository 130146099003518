export const MaliciousScoreConfig = {
  green: {
    variant: 'green',
    text: 'Benign',
    threshold: 0,
  },

  yellow: {
    variant: 'yellow',
    text: 'Suspicious',
    threshold: 50,
  },
  red: {
    variant: 'red',
    text: 'Malicious',
    threshold: 70,
  },
};

export function getTrustScoreConfig(score: number) {
  let config;
  if (score >= MaliciousScoreConfig.red.threshold) {
    config = MaliciousScoreConfig.red;
  } else if (score >= MaliciousScoreConfig.yellow.threshold) {
    config = MaliciousScoreConfig.yellow;
  } else {
    config = MaliciousScoreConfig.green;
  }
  return config;
}

export interface ThreatTypeProps {
  Trojan: number;
  Dropper: number;
  Downloader: number;
  Malware: number;
  Spyware: number;
  Infostealer: number;
  Backdoor: number;
  Ransomware: number;
  PUA: number;
  Hacktool: number;
  Dialer: number;
  Adware: number;
}

export const transformAndCleanThreatTypes = results => {
  const transformations = {
    Trojan: ['Trojan', 'Dropper', 'Downloader'],
    Malware: ['Malware'],
    Spyware: ['Spyware', 'Infostealer'],
    Backdoor: ['Backdoor'],
    Ransomware: ['Ransomware'],
    PUA: ['PUA'],
    Hacktool: ['Hacktool'],
    Dialer: ['Dialer'],
    Adware: ['Adware'],
  };

  return Object.entries(transformations).reduce((acc, [key, fields]) => {
    if (!results) {
      acc[key] = 0;
      return acc;
    }

    acc[key] = fields.reduce((sum, field) => sum + (Number(results[field]) ?? 0), 0);
    return acc;
  }, {});
};
