import { useState, useEffect } from 'react';
import { Box, LinearProgress } from '@mui/material';
import _ from 'lodash';

import { QTable } from '../../components/QTable/QTable';
import { QTableBody } from '../../components/QTable/QTableBody';
import { QTypography } from '../../components/QTypography/QTypography';
import { AsyncTablePagination } from '../AsyncTablePagination/AsyncTablePagination';
import { GetEvidencesCallback } from '../RulesTable/RulesTable';
import { QCardContent } from '../../components/QCard/QCardContent';
import { QCard } from '../../components/QCard/QCard';
import { QCardHeader } from '../../components/QCard/QCardHeader';
import { QDivider } from '../../components/QDivider/QDivider';
import { QBadge } from '../../components/QBadge/QBadge';
import { QTableRow } from '../../components/QTable/QTableRow';
import { QTableCell } from '../../components/QTable/QTableCell';

import { LocationEvidence } from './LocationEvidence';

interface EvidenceTableProps {
  description: string;
  ruleGuid: string;
  uuid: string;
  isExpanded: boolean;
  getEvidencesCallback: GetEvidencesCallback;
}

export const EvidenceTable = ({ description, ruleGuid, uuid, isExpanded, getEvidencesCallback }: EvidenceTableProps) => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const pageSizeOptions = [
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
  ];
  const [paginationOptions, setPaginationOptions] = useState({
    total: 0,
    lastPage: 0,
    perPage: 0,
  });
  const [evidences, setEvidences] = useState([]);

  const fetchEvidence = async () => {
    try {
      setLoading(true);
      const {
        data: { entries, pagination },
      } = await getEvidencesCallback(uuid, ruleGuid, page, pageSize);

      if (!entries) {
        throw new Error(`Could not retrieve evidence for ruleGuid: ${ruleGuid}, uuid: ${uuid}`);
      }

      setEvidences(entries.map(evidence => evidence.result));
      setPaginationOptions(pagination);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (isExpanded) {
      fetchEvidence();
    }
  }, [page, pageSize, description, isExpanded]);

  const handleChangePage = (newPage, event) => {
    event.preventDefault();
    setPage(newPage);
  };

  // Handle page size change
  const handlePageSizeChange = option => {
    setPageSize(option);
    setPage(1);
  };

  const removeDuplicateTextRegionSnippets = (locations = []) => {
    const seen = new Set();

    return locations.filter(location => {
      const uri = _.get(location, 'physicalLocation.artifactLocation.uri', null);
      const regionStartLine = _.get(location, 'physicalLocation.region.startLine', null);
      const regionEndLine = _.get(location, 'physicalLocation.region.endLine', null);
      const contextRegionStartLine = _.get(location, 'physicalLocation.contextRegion.startLine', null);
      const contextRegionEndLine = _.get(location, 'physicalLocation.contextRegion.endLine', null);

      if (uri && regionStartLine && regionEndLine && contextRegionStartLine && contextRegionEndLine) {
        const key = `${uri}-${regionStartLine}-${regionEndLine}-${contextRegionStartLine}-${contextRegionEndLine}`;
        if (seen.has(key)) {
          return false;
        }
        seen.add(key);
      }

      return true;
    });
  };

  return loading ? (
    <LinearProgress />
  ) : (
    <QCard
      sx={{
        padding: '0',
        backgroundColor: '#FFFFFF',
        flex: 1,
      }}
    >
      <QCardHeader
        disableTypography
        title={
          <Box display="flex" alignItems="center" gap="12px">
            Evidence
            <QBadge variant="basic" label={paginationOptions.total ? paginationOptions.total : null} />
          </Box>
        }
      />
      <QDivider />
      <QCardContent>
        <QTable>
          <QTableBody>
            {paginationOptions.total > 0 &&
              evidences.map((evidence, index) => (
                <QTableRow key={index} hoverDisabled>
                  <QTableCell style={{ backgroundColor: 'white' }}>
                    <div style={{ marginTop: '10px' }}>
                      <b>
                        <QTypography variant="bodyRegular">{evidence.message?.text || description}</QTypography>
                      </b>
                    </div>

                    {evidence.locations &&
                      removeDuplicateTextRegionSnippets(evidence.locations).map(location => {
                        return (
                          <LocationEvidence
                            key={location.id}
                            location={location}
                            codeFlows={evidence.codeFlows}
                            stacks={evidence.stacks}
                            evidence={evidence}
                          />
                        );
                      })}
                  </QTableCell>
                </QTableRow>
              ))}
          </QTableBody>
        </QTable>
        {paginationOptions.total > pageSizeOptions[0].value && (
          <AsyncTablePagination
            pagination={{
              lastPage: paginationOptions.lastPage,
              totalData: paginationOptions.total,
              dataPerPage: pageSize,
            }}
            getPage={handleChangePage}
            page={page}
            showRowsPerPage
            currentRows={evidences.length}
            handlePageSizeChange={handlePageSizeChange}
            pageSize={pageSize}
            pageSizeOptions={pageSizeOptions}
          />
        )}
      </QCardContent>
    </QCard>
  );
};
