import { QDoughnutCard } from '../../components/QDoughnutCard/QDoughnutCard';
import { theme } from '../../theme';

interface RulesData {
  error: number;
  warning: number;
  note: number;
  title?: string;
}

export const RulesChartCard = ({ error = 0, warning = 0, note = 0, title = 'Rules' }: RulesData) => (
  <QDoughnutCard
    title={title}
    data={{
      labels: ['Error', 'Warning', 'Note'],
      datasets: [
        {
          data: [error, warning, note],
          backgroundColor: [theme.palette.base.red, theme.palette.base.orange, theme.palette.base.blue],
        },
      ],
    }}
    options={{ maintainAspectRatio: false }}
  />
);
