import { useState } from 'react';

import moment from 'moment';
import { Box, Grid, Link } from '@mui/material';
import { Add, Android, Apple } from '@mui/icons-material';

import { QThemeProvider } from '../../components/QThemeProvider';
import { theme } from '../../theme';
import { QTypography } from '../../components/QTypography/QTypography';
import { QBadge } from '../../components/QBadge/QBadge';
import { QButton } from '../../components/QButton/QButton';
import { ScoreGauge } from './ScoreGauge';
import { Version } from './Version';

export interface TagProps {
  label: string;
  iconSrc: string;
  color: string;
}

export interface HeaderAndMetadataProps {
  packageName: string;
  platform: 'android' | 'ios';
  version: string;
  appName?: string;
  externalId?: string;
  testScriptName?: string;
  submissionTag?: string;
  submittedAt?: Date;
  score?: number;
  appIconUrls?: Array<string>;
  appUrl?: string;
  viewMetadataOnClick?: any;
  substitutedVersion?: string | null;
  tag?: TagProps;
}

const getDefaultIcon = (platform, fontSize) => {
  return platform === 'android' ? <Android sx={{ fontSize, color: theme.palette.androidGreen }} /> : <Apple sx={{ fontSize }} />;
};

export const AppIconImage = ({
  platform,
  appIconUrls = [],
  appUrl = null,
  imageSize = '122px',
}: {
  platform: 'android' | 'ios';
  appIconUrls?: string[];
  appUrl?: string;
  imageSize?: string;
}) => {
  const [appIconUrlIndex, setAppIconUrlIndex] = useState(0);

  const handleImageError = () => {
    setAppIconUrlIndex(prevIndex => prevIndex + 1);
  };

  return (
    <Link href={appUrl}>
      {appIconUrls.length > 0 && appIconUrlIndex < appIconUrls.length ? (
        <img
          alt="App Icon"
          src={appIconUrls[appIconUrlIndex]}
          height={imageSize}
          width={imageSize}
          style={{ borderRadius: '13.75px' }}
          onError={handleImageError}
        />
      ) : (
        getDefaultIcon(platform, '122px')
      )}
    </Link>
  );
};

export const HeaderAndMetadata = ({
  packageName,
  platform,
  appName,
  version,
  externalId,
  testScriptName,
  submissionTag,
  submittedAt,
  score,
  appIconUrls = [],
  appUrl = null,
  viewMetadataOnClick = () => {},
  substitutedVersion = null,
  tag,
}: HeaderAndMetadataProps) => (
  <QThemeProvider>
    <Grid container gap="16px">
      <Grid item>
        <AppIconImage appIconUrls={appIconUrls} appUrl={appUrl} platform={platform} />
      </Grid>
      {tag && (
        <Grid item>
          <Box
            sx={{
              width: 8,
              height: '100%',
              backgroundColor: tag.color,
            }}
          />
        </Grid>
      )}
      <Grid item>
        <Grid container direction={'column'} rowSpacing={'4px'}>
          {appName && (
            <Grid item container alignItems="center" wrap="nowrap" gap={2}>
              <QTypography variant="h3Bold" sx={{ whiteSpace: 'nowrap' }} color={tag?.color || theme.palette.base.black}>
                {appName}
              </QTypography>
              {tag && (
                <Grid container item alignItems="center" wrap="nowrap" gap={1}>
                  <img width={24} height={24} src={tag.iconSrc} alt="Tag icon" />
                  <QTypography variant="h6Bold" color={tag.color}>
                    {tag.label}
                  </QTypography>
                </Grid>
              )}
            </Grid>
          )}
          <Grid item>
            <QTypography variant="h6Regular" color={theme.palette.greyOverride[600]}>
              {packageName}
            </QTypography>
          </Grid>
          <Grid item container alignItems="center" gap="4px">
            {getDefaultIcon(platform, '19px')}
            <Version version={version} substitutedVersion={substitutedVersion} />
          </Grid>
          {externalId && (
            <Grid item>
              <QTypography variant="h6Regular" color={theme.palette.greyOverride[600]}>
                {`EID: ${externalId}`}
              </QTypography>
            </Grid>
          )}
          {testScriptName && (
            <Grid item>
              <QTypography variant="h6Regular" color={theme.palette.greyOverride[600]}>
                {`Test Script: ${testScriptName}`}
              </QTypography>
            </Grid>
          )}
          <Grid item container alignItems="center" gap="4px">
            {submissionTag && <QBadge variant="black" label={submissionTag} />}
            {submittedAt && (
              <QTypography variant="h6Regular" color={theme.palette.greyOverride[600]}>
                {`${submissionTag ? 'on' : ''} ${moment(submittedAt).format('MM/DD/YYYY-hh:mm:ss A')}`}
              </QTypography>
            )}
            <QButton variant="basic" onClick={() => viewMetadataOnClick()} endIcon={<Add />}>
              View metadata
            </QButton>
          </Grid>
        </Grid>
      </Grid>
      {(score || score === 0) && (
        <Grid item display="flex" justifyContent="flex-end" flexGrow={1}>
          <ScoreGauge score={score} size={260} />
        </Grid>
      )}
    </Grid>
  </QThemeProvider>
);
