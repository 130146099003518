import { Box } from '@mui/material';
import { QTypography } from '../../components/QTypography/QTypography';
import { QCard } from '../../components/QCard/QCard';
import { QCardHeader } from '../../components/QCard/QCardHeader';
import { QCardContent } from '../../components/QCard/QCardContent';
import { QScoreShield } from '../../components/QScoreShield/QScoreShield';
import { theme } from '../../theme';

interface MaliciousScoreProps {
  score: number;
}

export const MaliciousScoreConfig = {
  green: {
    variant: 'green',
    text: 'Benign',
    threshold: 0,
  },

  yellow: {
    variant: 'yellow',
    text: 'Suspicious',
    threshold: 50,
  },
  red: {
    variant: 'red',
    text: 'Malicious',
    threshold: 70,
  },
};

export const MaliciousScoreChart = ({ score }: MaliciousScoreProps) => {
  const config = getTrustScoreConfig(score);
  const { text, variant } = config;
  return (
    <QCard sx={{ height: '100%' }}>
      <QCardHeader
        title="Malicious Score"
        sx={{
          '& .MuiTypography-root': {
            color: theme.palette.text.primary,
            fontSize: '18px',
          },
        }}
      />
      <QCardContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            gap: 1,
            borderRadius: '8px',
            padding: '8px 16px',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <QScoreShield score={score} variant={variant} height="100px" width="100px" />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <QTypography variant="h2Medium">{text}</QTypography>
          </Box>
        </Box>
      </QCardContent>
    </QCard>
  );
};

function getTrustScoreConfig(score: number) {
  let config;
  if (score >= MaliciousScoreConfig.red.threshold) {
    config = MaliciousScoreConfig.red;
  } else if (score >= MaliciousScoreConfig.yellow.threshold) {
    config = MaliciousScoreConfig.yellow;
  } else {
    config = MaliciousScoreConfig.green;
  }
  return config;
}
