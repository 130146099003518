import { Box, Icon } from '@mui/material';
import { QTypography } from '../QTypography/QTypography';
import GreenShield from './icons/GreenShield.svg';
import YellowShield from './icons/YellowShield.svg';
import RedShield from './icons/RedShield.svg';

interface SafetyScoreBadgeProps {
  score: number;
  variant: 'green' | 'yellow' | 'red';
  height: string;
  width: string;
}

export const ScoreShieldStyle = {
  green: {
    icon: GreenShield,
    color: '#000',
  },

  yellow: {
    icon: YellowShield,
    color: '#000',
  },
  red: {
    icon: RedShield,
    color: '#FFF',
  },
};

function getColorAndIconFromVariant(variant: string) {
  let style;
  if (variant === 'green') {
    style = ScoreShieldStyle.green;
  } else if (variant === 'yellow') {
    style = ScoreShieldStyle.yellow;
  } else {
    style = ScoreShieldStyle.red;
  }
  return style;
}

export const QScoreShield = ({ score, variant, height, width }: SafetyScoreBadgeProps) => {
  const { color, icon } = getColorAndIconFromVariant(variant);
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <QTypography
        variant="h1Bold"
        style={{
          color,
          position: 'absolute',
          transform: 'translateY(-10%)',
        }}
      >
        {score}
      </QTypography>
      <Icon sx={{ height: '100%', width: '100%' }}>
        <img src={icon} style={{ height, width }} />
      </Icon>
    </Box>
  );
};
