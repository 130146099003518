import { Box } from '@mui/material';
import { QTypography } from '../../components/QTypography/QTypography';
import { SubstitutedVersion } from './SubstitutedVersion';
import { theme } from '../../theme';

export const Version = ({ version, substitutedVersion }: { version: string; substitutedVersion?: string }) => {
  // QScout Guardian report specific. If substitutedVersion is provided and is different from version, display substitutedVersion with a tooltip.
  const isSubstitutedVersionUsed = version && substitutedVersion && version.toLowerCase() !== substitutedVersion.toLowerCase();

  return isSubstitutedVersionUsed ? (
    <Box display="flex" alignItems="center">
      <QTypography variant="bodyRegular" color={theme.palette.greyOverride[600]}>
        {version}
      </QTypography>
      <SubstitutedVersion substitutedVersion={substitutedVersion} />
    </Box>
  ) : (
    <QTypography variant="h6Regular" color={theme.palette.greyOverride[600]}>
      {version}
    </QTypography>
  );
};
