import { SyncAltOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import { QTypography } from '../../components/QTypography/QTypography';

// A component specific only to the QScout Guardian report that displays a substituted version if analysis for the original version is not available.
export const SubstitutedVersion = ({ substitutedVersion }: { substitutedVersion: string }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        display: 'flex',
        padding: '4px 16px',
        gap: '8px',
        borderRadius: '16px',
        background: 'var(--Secondary-Safeguard-Light-mode-20, #D4DDF5)',
        margin: '0 8px',
      }}
    >
      <SyncAltOutlined />
      <QTypography variant="smallRegular" color={'var(--Text-Color-Cipher, #0A0A0A)'}>
        Substituted with {substitutedVersion}
      </QTypography>
    </Box>
  );
};
