import { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid } from '@mui/material';
import { ExpandMore, LaunchOutlined, TableRows } from '@mui/icons-material';
import _ from 'lodash';

import { QTable } from '../../components/QTable/QTable';
import { QTableBody } from '../../components/QTable/QTableBody';
import { QTableCell } from '../../components/QTable/QTableCell';
import { QTableRow } from '../../components/QTable/QTableRow';
import { QTypography } from '../../components/QTypography/QTypography';
import { theme } from '../../theme';
import { QBadge } from '../../components/QBadge/QBadge';
import { QChip } from '../../components/QChip/QChip';
import { QButton } from '../../components/QButton/QButton';
import { QCard } from '../../components/QCard/QCard';
import { QCardContent } from '../../components/QCard/QCardContent';
import { QDivider } from '../../components/QDivider/QDivider';
import { QCardHeader } from '../../components/QCard/QCardHeader';
import { Regulations } from './Regulations';
import { EvidenceTable } from '../EvidenceTable/EvidenceTable';

interface GetEvidencesCallbackResponse {
  data: {
    entries: any[];
    pagination: any;
  };
}

interface RulesRowProps {
  ruleSummary: RuleSummary;
}

interface ExpandableRulesRowProps extends RulesRowProps {
  getEvidencesCallback: GetEvidencesCallback;
  uuid: string;
}

interface RuleSummary {
  occurrences: number | string;
  level: string;
  guid: string;
  rule: any;
  details?: any;
  tags?: string[];
}

export interface GetEvidencesCallback {
  (uuid: string, ruleGuid: string, page: number, pageSize: number): Promise<GetEvidencesCallbackResponse>;
}

export interface RulesTableProp {
  uuid: string;
  ruleSummaries: RuleSummary[];
  getEvidencesCallback?: GetEvidencesCallback;
  expandable?: boolean;
  emptyTableText?: string;
}

const getRiskBadgeVariant = (level: string) => {
  if (level === 'error') {
    return 'red';
  }
  if (level === 'warning') {
    return 'orange';
  }
  if (level === 'note') {
    return 'blue';
  }
  return 'grey';
};

export const RulesTable = ({
  ruleSummaries,
  uuid,
  getEvidencesCallback = null,
  expandable = true,
  emptyTableText = 'No Findings Found',
}: RulesTableProp) => {
  const RulesTableBody = () => {
    if (ruleSummaries.length === 0) {
      return (
        <QTableBody>
          <QTableRow hoverDisabled>
            <QTableCell
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '16px',
                height: '400px',
                fontSize: '48px',
              }}
            >
              <TableRows fontSize="inherit" />
              <QTypography variant="h4Medium" sx={{ color: '#000' }}>
                {emptyTableText}
              </QTypography>
            </QTableCell>
          </QTableRow>
        </QTableBody>
      );
    }

    return (
      <QTableBody>
        {expandable
          ? ruleSummaries.map(ruleSummary => (
              <ExpandableRulesRow
                key={ruleSummary.guid}
                ruleSummary={ruleSummary}
                uuid={uuid}
                getEvidencesCallback={getEvidencesCallback}
              />
            ))
          : ruleSummaries.map(ruleSummary => <NonExpandableRulesRow key={ruleSummary.guid} ruleSummary={ruleSummary} />)}
      </QTableBody>
    );
  };

  return (
    <QTable>
      <RulesTableBody />
    </QTable>
  );
};

const ExpandableRulesRow = ({ ruleSummary, uuid, getEvidencesCallback }: ExpandableRulesRowProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { rule, occurrences, level, details } = ruleSummary;
  const riskBadgeVariant = getRiskBadgeVariant(level);

  const isNotEmpty = regulations => regulations && !_.isEmpty(regulations);

  return (
    <QTableRow hoverDisabled>
      <QTableCell padding="none">
        <Accordion
          expanded={isExpanded}
          onChange={() => setIsExpanded(!isExpanded)}
          sx={{
            backgroundColor: theme.palette.greyOverride[50],
            boxShadow: 'none',
            borderRadius: '0px',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore sx={{ width: '20px', height: '20px' }} />}
            sx={{
              flexDirection: 'row-reverse',
              alignItems: 'start',
              padding: '14px 16px 14px 16px',
              '.MuiAccordionSummary-content': { margin: '0' },
            }}
          >
            <Grid container marginLeft="16px" direction="column" gap="8px">
              <RuleName rule={rule} />
              <Grid item container gap="8px">
                <QChip variant="secondary" label={`${occurrences} occurrences`} sx={{ height: '24px', minWidth: '110px' }} />
                <QBadge variant={riskBadgeVariant} label={_.capitalize(level)} sx={{ height: '24px', minWidth: '86px' }} />
                {details.exploitable && (
                  <QBadge
                    variant="basic"
                    label="Exploitable"
                    sx={{ height: '24px', backgroundColor: theme.palette.greyOverride.tooltip }}
                  />
                )}
                {details.cvss_score && (
                  <QButton
                    variant="outlined"
                    endIcon={<LaunchOutlined />}
                    href={details.cvss_link}
                    style={{
                      fontSize: '12px',
                      height: '24px',
                    }}
                  >
                    CVSS: {details.cvss_score}
                  </QButton>
                )}
                {details.cwe && (
                  <QButton
                    variant="outlined"
                    endIcon={<LaunchOutlined />}
                    href={details.cwe_link}
                    style={{
                      fontSize: '12px',
                      height: '24px',
                    }}
                  >
                    {details.cwe.split(':')[0]}
                  </QButton>
                )}
              </Grid>
              <RuleDescription details={details} />
            </Grid>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              alignItems: 'start',
              padding: '0px 16px 14px 52px',
              '.MuiAccordionSummary-content': { margin: '0' },
            }}
          >
            {details && (
              <Box
                display="flex"
                columnGap="16px"
                style={{
                  width: '100%',
                  marginBottom: '16px',
                }}
              >
                {(details.threat_profile || details.remediation || details.impact) && (
                  <QCard
                    sx={{
                      flex: 2,
                      padding: '24px',
                      backgroundColor: theme.palette.base.white,
                    }}
                  >
                    <QCardContent
                      sx={{
                        padding: '14px 16px 14px 16px',
                      }}
                    >
                      {details.threat_profile && (
                        <Grid container direction="column" gap="12px">
                          <QTypography
                            variant="h5Bold"
                            style={{
                              color: '#000000',
                            }}
                          >
                            Threat Details
                          </QTypography>
                          <p>{details.threat_profile}</p>
                        </Grid>
                      )}
                      {details.threat_profile && details.remediation && (
                        <QDivider
                          style={{
                            borderColor: '#E0E0E0',
                            marginTop: '14px',
                            marginBottom: '14px',
                          }}
                        />
                      )}
                      {details.remediation && (
                        <Box style={{ display: 'flex', flexDirection: 'column' }} rowGap="12px">
                          <QTypography
                            variant="h5Bold"
                            style={{
                              color: '#000000',
                            }}
                          >
                            Remediation
                          </QTypography>
                          <p>{details.remediation}</p>
                        </Box>
                      )}
                      {details.impact && (details.remediation || details.threat_profile) && (
                        <QDivider
                          style={{
                            borderColor: '#E0E0E0',
                            marginTop: '14px',
                            marginBottom: '14px',
                          }}
                        />
                      )}
                      {details.impact && (
                        <Box style={{ display: 'flex', flexDirection: 'column' }} rowGap="12px">
                          <QTypography
                            variant="h5Bold"
                            style={{
                              color: '#000000',
                            }}
                          >
                            Finding Impact
                          </QTypography>
                          <p>{details.impact}</p>
                        </Box>
                      )}
                    </QCardContent>
                  </QCard>
                )}
                {(isNotEmpty(details.owasp) || isNotEmpty(details.niap) || isNotEmpty(details.gdpr)) && (
                  <QCard
                    sx={{
                      flex: 1,
                      padding: '24px',
                      backgroundColor: theme.palette.base.white,
                    }}
                  >
                    <QCardHeader
                      disableTypography
                      title={
                        <QTypography
                          variant="h5Bold"
                          style={{
                            color: theme.palette.base.black,
                          }}
                        >
                          Compliance
                        </QTypography>
                      }
                    />
                    <QCardContent>
                      <Regulations details={details} />
                    </QCardContent>
                  </QCard>
                )}
              </Box>
            )}
            {isNotEmpty(details) && (
              <EvidenceTable
                description={details.description}
                ruleGuid={ruleSummary?.guid}
                uuid={uuid}
                isExpanded={isExpanded}
                getEvidencesCallback={getEvidencesCallback}
              />
            )}
          </AccordionDetails>
        </Accordion>
      </QTableCell>
    </QTableRow>
  );
};

const NonExpandableRulesRow = ({ ruleSummary }: RulesRowProps) => {
  const { rule, occurrences, level, details, tags = [] } = ruleSummary;
  const riskBadgeVariant = getRiskBadgeVariant(level);

  return (
    <QTableRow hoverDisabled>
      <QTableCell sx={{ padding: '14px 16px 14px 16px' }}>
        <Grid container marginLeft="16px" direction="column" gap="8px">
          <RuleName rule={rule} />
          <Grid item container gap="8px">
            <QBadge variant={riskBadgeVariant} label={_.capitalize(level)} sx={{ height: '24px', minWidth: '86px' }} />
            <QChip
              variant="secondary"
              label={`${occurrences} occurrences`}
              sx={{ height: '24px', minWidth: '110px', color: theme.palette.chart.darkTeal }}
            />
            {tags.map((value, index) => (
              <QChip
                variant="secondary"
                label={`${value}`}
                sx={{ height: '24px', minWidth: '110px', color: theme.palette.chart.darkTeal }}
                key={index}
              />
            ))}
          </Grid>
          <RuleDescription details={details} />
        </Grid>
      </QTableCell>
    </QTableRow>
  );
};

const RuleName = ({ rule }: any) => {
  if (!rule) return <></>;

  return (
    <Grid item>
      <QTypography variant="h5Regular" color={theme.palette.base.black}>
        {rule.name ?? rule.shortDescription?.text ?? rule.fullDescription?.text?.split('.')[0]}
      </QTypography>
    </Grid>
  );
};

const RuleDescription = ({ details }: any) => {
  if (!details) return <></>;

  return (
    <Grid item>
      <QTypography
        variant="h6Regular"
        style={{
          color: theme.palette.greyOverride[600],
        }}
      >
        {details.description}
      </QTypography>
    </Grid>
  );
};
