import { Close } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import { LocationEvidence } from './LocationEvidence';
import { QIconButton } from '../../components/QButton/QIconButton';
import { QTable } from '../../components/QTable/QTable';
import { QTableBody } from '../../components/QTable/QTableBody';
import { QTypography } from '../../components/QTypography/QTypography';
import { QTableRow } from '../../components/QTable/QTableRow';
import { QTableCell } from '../../components/QTable/QTableCell';

export function CodeFlows({ isOpen, toggle, codeFlows, startLocation }: any) {
  return (
    <Dialog open={isOpen} maxWidth="xl">
      <DialogTitle sx={{ padding: '0px' }}>
        <QIconButton onClick={toggle} style={{ float: 'right', color: '#000000', margin: '12px' }}>
          <Close />
        </QIconButton>
      </DialogTitle>
      <DialogContent>
        <QTable>
          <QTableBody>
            {codeFlows.map((codeFlow, codeFlowIndex) => (
              <div key={codeFlowIndex} style={codeFlowIndex > 0 ? { paddingTop: '12px' } : {}}>
                <QTypography variant="h5Medium">{startLocation}</QTypography>
                <div style={{ paddingLeft: '8px' }}>
                  {codeFlow.threadFlows?.map(threadFlow =>
                    threadFlow.locations?.map((codeFlowLocation, index) => (
                      <QTableRow key={index} hoverDisabled>
                        <QTableCell style={{ backgroundColor: 'white' }}>
                          <div key={index}>
                            <LocationEvidence location={codeFlowLocation.location} step={index + 1} />
                          </div>
                        </QTableCell>
                      </QTableRow>
                    )),
                  )}
                </div>
              </div>
            ))}
          </QTableBody>
        </QTable>
      </DialogContent>
    </Dialog>
  );
}
