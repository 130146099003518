/* eslint-disable @typescript-eslint/no-unused-vars */
import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { QButton } from '../../components/QButton/QButton';
import { QModal, QModalActions, QModalContent, QModalTitle } from '../../components/QModal/QModal';

export const ThreatTypeDistributionInfoModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <>
      <QModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title={
          <QModalTitle onClose={() => setIsOpen(false)} closeIcon={<CloseIcon />}>
            <Typography variant="h3Medium" id="tooltip-modal-title">
              Malicious Report
            </Typography>
          </QModalTitle>
        }
        content={
          <QModalContent>
            <Typography variant="bodyRegular">
              Quokka&apos;s Malicious Report takes a behavior-based machine learning approach to determine how a given mobile application
              behaves compared to known types of malware. Similarity to a particular threat type is factored into a sum of all threat
              behavior examined, assigning the app an overall Malicious Score and behavioral designation of benign, suspicious or malicious.
              The Malicious Report is available for Android apps, with support for iOS apps coming soon.
            </Typography>
          </QModalContent>
        }
        actions={
          <QModalActions>
            <QButton variant="filled" color="primary" onClick={() => setIsOpen(false)}>
              Got it
            </QButton>
          </QModalActions>
        }
      />
    </>
  );
};
