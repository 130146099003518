import { ThemeProvider, CardHeader, createTheme } from '@mui/material';
import { CardHeaderProps } from '@mui/material/CardHeader';
import { deepmerge } from '@mui/utils';
import { theme } from '../../theme';

const getCardHeaderTheme = (palette = theme.palette) =>
  createTheme({
    components: {
      MuiCardHeader: {
        defaultProps: {
          titleTypographyProps: {
            sx: { fontSize: 'unset' },
          },
          subheaderTypographyProps: {
            sx: { fontSize: 'unset' },
          },
        },
        styleOverrides: {
          content: {
            fontSize: '21px',
            color: palette.text.secondary,
          },
        },
      },
    },
  });

export const QCardHeader = ({ ...props }: CardHeaderProps) => {
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getCardHeaderTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <CardHeader {...props} />
    </ThemeProvider>
  );
};
