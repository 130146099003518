import { Chart, ArcElement } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import { theme } from '../../theme';

export const BackgroundDoughnut = props => {
  Chart.register(ArcElement);

  const options = {
    circumference: 180,
    rotation: -90,
    cutout: '95%',
    aspectRatio: 2,
    layout: {
      padding: 20,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const data = {
    datasets: [
      {
        data: [100, 0],
        backgroundColor: ['#000'], // placeholder, its updated in the plugin
        borderWidth: 0,
      },
    ],
  };

  const plugins = [
    {
      id: 'customGradient',
      beforeDraw: chart => {
        const { ctx, chartArea } = chart;
        if (!chartArea) return;

        const gradient = ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0);
        gradient.addColorStop(0.1, theme.palette.risk.low);
        gradient.addColorStop(0.4, theme.palette.risk.medium);
        gradient.addColorStop(0.7, theme.palette.risk.high);
        gradient.addColorStop(1, theme.palette.risk.critical);

        chart.data.datasets[0].backgroundColor = [gradient];
        chart.update();
      },
    },
  ];

  return <Doughnut {...props} data={data} options={options} plugins={plugins} />;
};
