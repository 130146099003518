import { Close } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

import { QIconButton } from '../../components/QButton/QIconButton';
import { QTable } from '../../components/QTable/QTable';
import { QTableBody } from '../../components/QTable/QTableBody';
import { QTypography } from '../../components/QTypography/QTypography';

export function Stacks({ isOpen, toggle, stacks, startLocation }: any) {
  const styles = {
    additionalInfo: {
      backgroundColor: '#DDEFE9',
    },
  };

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        style: { width: '1000px' },
      }}
    >
      <DialogTitle sx={{ padding: '0px' }}>
        <QIconButton onClick={toggle} style={{ float: 'right', color: '#000000', margin: '12px' }}>
          <Close />
        </QIconButton>
      </DialogTitle>
      <DialogContent>
        <QTable>
          <QTableBody>
            <QTypography variant="h5Medium">{startLocation}</QTypography>

            <SyntaxHighlighter key={Math.random()}>
              {stacks.map((stack, stackIndex) =>
                stack.frames?.map((frame, frameIndex) =>
                  frame.location?.logicalLocations?.map((location, locationIndex) => {
                    let methodName;
                    let additionalInfo;
                    const fullName = location.fullyQualifiedName;

                    if (fullName && fullName.includes('(')) {
                      const firstParenthesisIndex = fullName.indexOf('(');
                      methodName = fullName.substring(0, firstParenthesisIndex);
                      additionalInfo = fullName.substring(firstParenthesisIndex);
                    } else {
                      methodName = fullName;
                      additionalInfo = '';
                    }

                    const key = `stack-${stackIndex}-frame-${frameIndex}-location-${locationIndex}`;

                    return (
                      <div key={key}>
                        <span>{methodName}</span>
                        {additionalInfo && <span style={styles.additionalInfo}>{additionalInfo}</span>}
                        <br />
                      </div>
                    );
                  }),
                ),
              )}
            </SyntaxHighlighter>
          </QTableBody>
        </QTable>
      </DialogContent>
    </Dialog>
  );
}
