import { QDoughnutCard } from '../../components/QDoughnutCard/QDoughnutCard';
import { theme } from '../../theme';

interface PermissionsData {
  used: number;
  missing: number;
  unused: number;
  unknown: number;
}

export const PermissionsChartCard = ({ used = 0, missing = 0, unused = 0, unknown = 0 }: PermissionsData) => (
  <QDoughnutCard
    title="Permissions"
    data={{
      labels: ['Used', 'Missing', 'Unused', 'Unknown'],
      datasets: [
        {
          data: [used, missing, unused, unknown],
          backgroundColor: [
            theme.palette.chart.paleAqua,
            theme.palette.chart.darkTeal,
            theme.palette.chart.peach,
            theme.palette.chart.seaGreen,
          ],
        },
      ],
    }}
    options={{ maintainAspectRatio: false }}
  />
);
