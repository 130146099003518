/* eslint-disable react/forbid-prop-types */
import { useState } from 'react';
import _ from 'lodash';
import LazyLoad from 'react-lazyload';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

import { QTypography } from '../../components/QTypography/QTypography';
import { QButton } from '../../components/QButton/QButton';

import { CodeFlows } from './CodeFlows';
import { Stacks } from './Stacks';

const ViewMoreEvidenceButton = ({ title, onClick }: any) => (
  <QButton
    variant="ghost"
    style={{
      paddingLeft: '10px',
      fontWeight: 700,
      fontSize: 13,
      color: '#487F87',
    }}
    onClick={onClick}
  >
    {title}
  </QButton>
);

const getLanguageFromFileName = fileName => {
  const ext = fileName.split('.').pop();
  if (ext === 'java' || ext === 'xml' || ext === 'json') {
    return ext;
  }
  if (ext === 'ts' || ext === 'tsx') {
    return 'typescript';
  }
  if (ext === '.js' || ext === 'jsx') {
    return 'javascript';
  }
  return '';
};

export function LocationEvidence({ evidence, location, codeFlows, stacks, step }: any) {
  const [showCodeFlowModal, setShowCodeFlowModal] = useState(false);
  const [showStackModal, setShowStackModal] = useState(false);

  const uri = _.get(location, 'physicalLocation.artifactLocation.uri', null);
  const address = _.get(location, 'physicalLocation.address.fullyQualifiedName', null);

  // location values for a text region
  const locationLine = _.get(location, 'physicalLocation.region.startLine', -1);
  const startLine = _.get(location, 'physicalLocation.contextRegion.startLine', null) || locationLine;
  const endLine = _.get(location, 'physicalLocation.contextRegion.endLine', null) || _.get(location, 'physicalLocation.region.endLine', -1);
  const lineCode =
    _.get(location, 'physicalLocation.contextRegion.snippet.text', null) || _.get(location, 'physicalLocation.region.snippet.text', null);

  // location values for a binary region
  const byteOffset = _.get(location, 'physicalLocation.region.byteOffset', null);
  const startAddress = _.get(location, 'physicalLocation.address.absoluteAddress', -1).toString(16);
  const binaryLineCode = _.get(location, 'physicalLocation.region.snippet.rendered.text', null);

  const blockCode = _.get(location, 'physicalLocation.contextRegion.snippet.text', '');
  const region = (lineCode || binaryLineCode) ?? blockCode;
  const code = uri ?? address ?? '';

  let formattedLocation;
  if (stacks && address) {
    formattedLocation = address;
  } else if (startLine !== -1 || lineCode) {
    // text region
    formattedLocation = `${code} at line: ${locationLine}`;
  } else if (byteOffset && (startAddress !== '-1' || binaryLineCode)) {
    // binary region always has byteOffset https://docs.oasis-open.org/sarif/sarif/v2.1.0/os/sarif-v2.1.0-os.html#_Toc34317696
    formattedLocation = `${code} at address: 0x${startAddress}`;
  } else {
    formattedLocation = `${code}`;
  }

  const locationTextColor = step ? '#757575' : '#000000';

  return (
    <>
      {codeFlows && (
        <CodeFlows
          isOpen={showCodeFlowModal}
          toggle={() => setShowCodeFlowModal(!showCodeFlowModal)}
          codeFlows={codeFlows}
          startLocation={formattedLocation}
        />
      )}

      {stacks && (
        <Stacks
          isOpen={showStackModal}
          toggle={() => setShowStackModal(!showStackModal)}
          stacks={stacks}
          startLocation={formattedLocation}
        />
      )}

      <div>
        {step > 0 && <QTypography variant="h5Medium">{`Step ${step}  `}</QTypography>}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <QTypography variant={step ? 'h6Medium' : 'bodyMedium'} style={{ color: locationTextColor }}>
              {formattedLocation}
            </QTypography>

            {codeFlows && <ViewMoreEvidenceButton title="View Code Flows" onClick={() => setShowCodeFlowModal(true)} />}
          </div>

          <div
            style={{
              textAlign: 'right',
            }}
          >
            <div>
              {evidence?.properties?.score && (
                <QTypography variant="bodyRegular">
                  Score:
                  {parseFloat(evidence.properties.score.toFixed(1))}
                </QTypography>
              )}
            </div>
          </div>
        </div>

        {stacks && <ViewMoreEvidenceButton title="View Stacks" onClick={() => setShowStackModal(true)} />}
      </div>

      {location.message?.text && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div>
            <QTypography variant="bodyRegular">{location.message.text}</QTypography>
          </div>
          <div
            style={{
              textAlign: 'right',
            }}
          >
            {evidence?.properties?.confidence && (
              <QTypography variant="bodyRegular">
                Confidence: {evidence.properties.confidence.charAt(0).toUpperCase() + evidence.properties.confidence.slice(1)}
              </QTypography>
            )}
          </div>
        </div>
      )}
      {!step
        ? region && (
            <LazyLoad>
              <SyntaxHighlighter
                key={Math.random()}
                data-testid="locations-evidence-code-test"
                language={getLanguageFromFileName(code)}
                wrapLines
                wrapLongLines
                startingLineNumber={startLine}
                showLineNumbers={startLine > 0}
                lineProps={lineNumber => ({
                  style: {
                    wordBreak: 'break-all',
                    whiteSpace: 'pre-wrap',
                    ...(locationLine === startLine + lineNumber - 1 && startLine !== endLine ? { backgroundColor: '#DDEFE9' } : {}),
                  },
                })}
              >
                {region}
              </SyntaxHighlighter>
            </LazyLoad>
          )
        : region && (
            <SyntaxHighlighter
              key={Math.random()}
              data-testid="locations-evidence-code-test"
              language={getLanguageFromFileName(code)}
              wrapLines
              wrapLongLines
              startingLineNumber={startLine}
              showLineNumbers={startLine > 0}
              lineProps={lineNumber => ({
                style: {
                  wordBreak: 'break-all',
                  whiteSpace: 'pre-wrap',
                  ...(locationLine === startLine + lineNumber - 1 && startLine !== endLine ? { backgroundColor: '#DDEFE9' } : {}),
                },
              })}
            >
              {region}
            </SyntaxHighlighter>
          )}
    </>
  );
}
