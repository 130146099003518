/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'lodash';
import { InfoOutlined } from '@mui/icons-material';
import { useState } from 'react';
import { QRadar } from '../../components/QRadar/QRadar';
import { QCard } from '../../components/QCard/QCard';
import { QCardHeader } from '../../components/QCard/QCardHeader';
import { theme } from '../../theme';
import { QCardContent } from '../../components/QCard/QCardContent';
import { QIconButton } from '../../components/QButton/QIconButton';
import { QTypography } from '../../components/QTypography/QTypography';
import { ThreatTypeDistributionInfoModal } from './ThreatTypeDistributionInfoModal';
import { ThreatTypeProps, transformAndCleanThreatTypes } from '../utils/MaliciousScoreUtils';

const threatsTypeTooltipText = {
  Trojan:
    'Malware that disguises itself as a legitimate app. Once installed it then delivers further threats such as spying on the user or altering the device.',
  Dropper: ' A malicious program that installs another file (usually a harmful program) onto a device.',
  Malware: 'Software designed in order to attack a user/device, often to steal data or cause damage to devices and systems.',
  Spyware: "Malware designed to gather information from a device and user in order to send to a third-party without the user's consent.",
  Infostealer:
    "A type of malware that disguises itself in order to collect sensitive information and send to a third-party without the user's consent",
  Backdoor: 'Malware that allows threat actors to bypass security measures and gain higher level access to a system.',
  Ransomware:
    'A malicious program that prevents users from accessing their device normally, usually by encrypting files or by locking the screen until a ransom is paid.',
  PUA: 'Potentially Unwanted Applications (PUA) are applications that may annoy or even pose risks to users and are typically bundled together with other software.',
  Hacktool: 'Riskware that can be used in order to attack, gain unauthorized access to, and/or extract information from a device.',
  Dialer: 'Malware that attempts to call other numbers in order to fraud users.',
  Adware: 'Malicious software that disrupts users by displaying unwanted advertisements.',
  Downloader: 'A type of malware that access the network and downloads another file (usually a harmful program) onto a device.',
};

interface ThreatTypeDistributionChartCardProps {
  threatTypes: ThreatTypeProps;
}

export const ThreatTypeDistributionChartCard = ({ threatTypes }: ThreatTypeDistributionChartCardProps) => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  if (_.isEmpty(threatTypes)) {
    return <></>;
  }

  const transformData = transformAndCleanThreatTypes(threatTypes);

  // Helper function to apply Circle Arc transformation to data
  const applyCircleArcScale = (data: any): any => {
    const getValue = (value: number): number => Math.sqrt(100 ** 2 - (value - 100) ** 2);

    return Array.isArray(data) ? data.map(value => getValue(value)) : getValue(data);
  };

  const radarData = {
    labels: Object.keys(transformData),
    datasets: [
      {
        label: 'Threat Levels',
        // Apply circle arc scale transformation to data
        data: applyCircleArcScale(Object.values(transformData)),
        borderWidth: 5,

        pointBackgroundColor(context) {
          const value = context.raw;

          if (value < applyCircleArcScale(40)) {
            return theme.palette.base.blue; // Blue for lower threat
          }
          if (value < applyCircleArcScale(70)) {
            return theme.palette.base.orange; // Orange for medium threat
          }
          return theme.palette.base.red; // Red for high threat
        },
      },
    ],
  };

  return (
    <>
      <ThreatTypeDistributionInfoModal isOpen={isInfoModalOpen} setIsOpen={setIsInfoModalOpen} />
      <QCard sx={{ height: '100%' }}>
        <QCardHeader
          title={
            <QTypography>
              Threat Type Distribution{' '}
              <QIconButton sx={{ ml: 1 }} onClick={() => setIsInfoModalOpen(true)}>
                <InfoOutlined />
              </QIconButton>
            </QTypography>
          }
          sx={{
            '& .MuiTypography-root': {
              color: theme.palette.text.primary,
              fontSize: '18px',
            },
            pb: 0,
          }}
        />
        <QCardContent
          sx={{
            p: '0 !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '350px',
            width: '350px',
            position: 'relative',
            margin: '0 auto',
          }}
        >
          <QRadar
            tooltipsText={threatsTypeTooltipText}
            data={radarData}
            gradientThreshold={{
              low: 0,
              medium: applyCircleArcScale(40) / 100,
              high: applyCircleArcScale(70) / 100,
            }}
            options={{
              animation: false,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: false,
                },
              },
            }}
          />
        </QCardContent>
      </QCard>
    </>
  );
};
