import { Box, Grid } from '@mui/material';
import { QTypography } from '../../components/QTypography/QTypography';
import { QCard } from '../../components/QCard/QCard';
import { QCardHeader } from '../../components/QCard/QCardHeader';
import { QCardContent } from '../../components/QCard/QCardContent';
import { QScoreShield } from '../../components/QScoreShield/QScoreShield';
import { theme } from '../../theme';
import { getTrustScoreConfig, ThreatTypeProps, transformAndCleanThreatTypes } from '../utils/MaliciousScoreUtils';

interface MaliciousScoreFactorsProps {
  score: number;
  threatTypes: ThreatTypeProps;
}

export const MaliciousScoreFactorsChart = ({
  score,
  threatTypes = {
    Trojan: 0,
    Dropper: 0,
    Malware: 0,
    Spyware: 0,
    Ransomware: 0,
    Adware: 0,
    Dialer: 0,
    Hacktool: 0,
    PUA: 0,
    Backdoor: 0,
    Downloader: 0,
    Infostealer: 0,
  },
}: MaliciousScoreFactorsProps) => {
  const config = getTrustScoreConfig(score);
  const transformedThreatTypes = transformAndCleanThreatTypes(threatTypes) as ThreatTypeProps;
  const { variant } = config;
  return (
    <QCard sx={{ height: '100%' }}>
      <QCardHeader
        title="Malicious Score"
        sx={{
          '& .MuiTypography-root': {
            color: theme.palette.text.primary,
            fontSize: '18px',
          },
        }}
      />
      <QCardContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            gap: 1,
            borderRadius: '8px',
            padding: '8px 16px',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '24px' }}>
            <QScoreShield score={score} variant={variant} height="100px" width="100px" />
          </Box>
          <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '8px 12px',
                borderRadius: '8px',
                backgroundColor: '#EEE',
                marginBottom: '16px',
              }}
            >
              <QTypography variant="h5Medium">Malicious Score Factors</QTypography>
            </Box>
            <Box sx={{ display: 'flex', padding: '0px 12px', alignItems: 'center' }}>
              <Grid container sx={{ display: 'flex', alignItems: 'flex-start', gap: '16px', flexDirection: 'row' }}>
                <Grid container>
                  <Grid item xs={4}>
                    <QTypography variant="h5Medium">{`${transformedThreatTypes?.Adware}`} </QTypography>
                    <QTypography variant="bodyRegular" sx={{ textDecoration: 'underline' }}>
                      Adware
                    </QTypography>
                  </Grid>
                  <Grid item xs={4}>
                    <QTypography variant="h5Medium">{`${transformedThreatTypes?.Dialer}`} </QTypography>
                    <QTypography variant="bodyRegular" sx={{ textDecoration: 'underline' }}>
                      Dialer
                    </QTypography>
                  </Grid>
                  <Grid item xs={4}>
                    <QTypography variant="h5Medium">{`${transformedThreatTypes?.Hacktool}`} </QTypography>
                    <QTypography variant="bodyRegular" sx={{ textDecoration: 'underline' }}>
                      Hacktool
                    </QTypography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4}>
                    <QTypography variant="h5Medium">{`${transformedThreatTypes?.Malware}`} </QTypography>
                    <QTypography variant="bodyRegular" sx={{ textDecoration: 'underline' }}>
                      Malware
                    </QTypography>
                  </Grid>
                  <Grid item xs={4}>
                    <QTypography variant="h5Medium">{`${transformedThreatTypes?.PUA}`} </QTypography>
                    <QTypography variant="bodyRegular" sx={{ textDecoration: 'underline' }}>
                      PUA
                    </QTypography>
                  </Grid>
                  <Grid item xs={4}>
                    <QTypography variant="h5Medium">{`${transformedThreatTypes?.Backdoor}`} </QTypography>
                    <QTypography variant="bodyRegular" sx={{ textDecoration: 'underline' }}>
                      Backdoor
                    </QTypography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4}>
                    <QTypography variant="h5Medium">{`${transformedThreatTypes?.Trojan}`} </QTypography>
                    <QTypography variant="bodyRegular" sx={{ textDecoration: 'underline' }}>
                      Trojan
                    </QTypography>
                  </Grid>
                  <Grid item xs={4}>
                    <QTypography variant="h5Medium">{`${transformedThreatTypes?.Spyware}`} </QTypography>
                    <QTypography variant="bodyRegular" sx={{ textDecoration: 'underline' }}>
                      Spyware
                    </QTypography>
                  </Grid>
                  <Grid item xs={4}>
                    <QTypography variant="h5Medium">{`${transformedThreatTypes?.Ransomware}`} </QTypography>
                    <QTypography variant="bodyRegular" sx={{ textDecoration: 'underline' }}>
                      Ransomware
                    </QTypography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </QCardContent>
    </QCard>
  );
};
