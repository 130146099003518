import { Chart, ArcElement } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import { theme } from '../../theme';

import { BackgroundDoughnut } from './BackgroundDonut';

interface ScoreGaugeProps {
  score: number | undefined;
  size?: number;
}

export const ScoreGauge = (props: ScoreGaugeProps) => {
  Chart.register(ArcElement);

  const { score, size = 251 } = props;

  let appRiskLevel: string;
  let gaugeColor: string;

  if (score >= 90) {
    appRiskLevel = 'Critical';
    gaugeColor = theme.palette.risk.critical;
  } else if (score >= 70) {
    appRiskLevel = 'High';
    gaugeColor = theme.palette.risk.high;
  } else if (score >= 40) {
    appRiskLevel = 'Medium';
    gaugeColor = theme.palette.risk.medium;
  } else {
    appRiskLevel = 'Low';
    gaugeColor = theme.palette.risk.low;
  }

  const centerTextPlugin = {
    id: 'centerTextPlugin',
    afterDatasetsDraw(chart) {
      const {
        ctx,
        chartArea: { top, left, right },
      } = chart;
      const centerX = chart.getDatasetMeta(0).data[0].x;
      const centerY = chart.getDatasetMeta(0).data[0].y;

      const drawText = (
        text: string,
        x: number,
        y: number,
        color = theme.palette.base.black,
        fontSize = 14,
        fontWeight = 600,
        textAlign = 'center',
        textBaseline = 'bottom',
      ) => {
        ctx.font = `${fontWeight} ${fontSize}px 'Nunito Sans', 'NunitoSans', 'sans-serif'`;
        ctx.textAlign = textAlign;
        ctx.textBaseline = textBaseline;
        ctx.fillStyle = color;
        ctx.fillText(text, x, y);
      };

      drawText('0', left + 5, centerY, theme.palette.greyOverride[600]);
      drawText('40', (left + centerX) / 2 - 5, top, theme.palette.greyOverride[600], 14, 600, 'left');
      drawText('70', (right + centerX) / 2 + 5, top, theme.palette.greyOverride[600], 14, 600, 'right');
      drawText('100', right, centerY, theme.palette.greyOverride[600], 14, 600, 'center');

      if (!score && score !== 0) {
        drawText('N/A', centerX, centerY);
      } else {
        // Draw risk level
        drawText(appRiskLevel, centerX, centerY);
        // Draw score with larger font
        drawText(`${score}`, centerX, centerY - 20, theme.palette.base.black, 33, 700);
      }
    },
  };

  const dataValue = score === undefined ? 0 : score;

  // If no options are passed we still want to force the legend styling for our design.
  const doughnutProps = {
    data: {
      datasets: [
        {
          data: [dataValue, 100 - dataValue],
          backgroundColor: [gaugeColor, theme.palette.chart.grey],
          borderWidth: 0,
        },
      ],
    },
    options: {
      circumference: 180,
      rotation: -90,
      cutout: '83%',
      aspectRatio: 2,
      layout: {
        padding: 20,
      },
      plugins: {
        tooltip: {
          enabled: false,
        },
      },
    },
    noLegend: true,
    plugins: [centerTextPlugin],
  };

  return (
    <div
      style={{
        width: `${size}px`,
        height: `${size / 2}px`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <Doughnut {...doughnutProps} />
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: `${size}px`,
          zIndex: 2,
        }}
      >
        <BackgroundDoughnut />
      </div>
    </div>
  );
};
