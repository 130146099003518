import { Box } from '@mui/material';

import { QDropdownWrapper } from '../../components/QDropdown/QDropdown';
import { QPagination } from '../../components/QPagination/QPagination';

interface RowsPerPageProps {
  page: number;
  rowsPerPage: number;
  totalRows: number;
  currentRows: number;
  handlePageSizeChange: any;
  pageSize: number;
  pageSizeOptions: any;
}

interface AsyncTablePaginationProps {
  page: number;
  pagination: any;
  getPage: any;
  showRowsPerPage?: boolean;
  currentRows?: number;
  handlePageSizeChange?: any;
  pageSize?: number;
  pageSizeOptions?: any;
}

const RowsPerPage = ({ page, rowsPerPage, totalRows, currentRows, handlePageSizeChange, pageSize, pageSizeOptions }: RowsPerPageProps) => {
  const handleChange = value => {
    handlePageSizeChange(pageSizeOptions.find(option => option.value === value));
  };

  return (
    <Box>
      <Box
        display="flex"
        style={{
          alignItems: 'center',
          columnGap: '5px',
          color: '#000000',
        }}
      >
        {totalRows === 0
          ? `Displaying 0 of 0`
          : `Displaying ${(page - 1) * rowsPerPage + 1} -
                  ${(page - 1) * rowsPerPage + currentRows} of ${totalRows}`}

        <QDropdownWrapper
          options={pageSizeOptions}
          onChange={event => {
            handleChange(event.target.value);
          }}
          closedLabel={pageSize}
          variant="outlined"
          formControlProps={{
            style: {
              paddingTop: '0px',
              paddingBottom: '0px',
            },
          }}
          labelProps={{
            disableAnimation: true,
            shrink: false,
            style: {
              transform: 'translate(16px, 6px) scale(1)',
              overflow: 'visible',
            },
          }}
          selectProps={{
            style: {
              background: 'none',
              paddingTop: '0px',
              paddingBottom: '4.5px',
              minWidth: '70px',
            },
          }}
        />
      </Box>
    </Box>
  );
};

export const AsyncTablePagination = ({
  page,
  pagination,
  getPage,
  showRowsPerPage = false,
  currentRows = null,
  handlePageSizeChange = null,
  pageSize = null,
  pageSizeOptions = null,
}: AsyncTablePaginationProps) => {
  return (
    <Box
      display="flex"
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '64px',
        backgroundColor: '#FAFAFA',
        paddingLeft: '15px',
        paddingRight: '15px',
      }}
    >
      {showRowsPerPage &&
      pagination.dataPerPage &&
      pagination.totalData &&
      currentRows &&
      handlePageSizeChange &&
      pageSize &&
      pageSizeOptions ? (
        <RowsPerPage
          page={page}
          rowsPerPage={pagination.dataPerPage}
          totalRows={pagination.totalData}
          currentRows={currentRows}
          handlePageSizeChange={handlePageSizeChange}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
        />
      ) : (
        <div />
      )}
      <QPagination count={pagination.lastPage} page={page} onChange={(event, selectedPage) => getPage(selectedPage, event)} />
    </Box>
  );
};
