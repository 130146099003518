import { ReactNode } from 'react';

import { Box, Drawer, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Close, ExpandMore } from '@mui/icons-material';
import _, { isEmpty } from 'lodash';
import moment from 'moment';
import { QTypography } from '../../components/QTypography/QTypography';
import { QDivider } from '../../components/QDivider/QDivider';
import { QLink } from '../../components/QList/QList';
import { QTable } from '../../components/QTable/QTable';
import { QTableCell } from '../../components/QTable/QTableCell';
import { QTableRow } from '../../components/QTable/QTableRow';
import { QTableBody } from '../../components/QTable/QTableBody';
import { QIconButton } from '../../components/QButton/QIconButton';
import { QChip } from '../../components/QChip/QChip';
import { theme } from '../../theme';

interface AppInfo {
  name?: string;
  version?: string;
  versionCode?: string;
  minSdk?: string;
  targetSdk?: string;
  fileSize?: string;
  submissionDate?: string;
  submissionTag?: string;
  sha1?: string;
  md5?: string;
}

interface MarketInfo {
  category?: string;
  lastUpdated?: string;
  download?: string;
  privacyPolicy?: string;
  targetSdk?: string;
  fileSize?: string;
  developer?: string;
  developerEmail?: string;
  developerUrl?: string;
}

interface CertificateInfo {
  name?: string;
  minimumOSversion?: string;
  platform?: string;
  platformVersion?: string;
  developer?: string;
  teamId?: string;
  authority?: string;
  applicationIdentifier?: string;
  creationDate?: string;
  expirationDate?: string;
  compiler?: string;
  provisioningProfileAppId?: string;
}

const InfoTable = ({ header, data }: { header: string; data: unknown[] }) => (
  <Accordion elevation={0}>
    <AccordionSummary
      expandIcon={<ExpandMore />}
      sx={{
        m: 0,
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper': {
          marginRight: '16px',
        },
      }}
    >
      <QTypography variant="h5Regular">{header}</QTypography>
    </AccordionSummary>
    <AccordionDetails sx={{ p: '0px 16px 14px 48px' }}>
      <QTable size="sm" sx={{ tableLayout: 'fixed', width: '100%' }}>
        <QTableBody>
          {data.map((row: unknown[], rowIndex) => (
            <QTableRow key={rowIndex}>
              {/* Render cells based on availability */}
              {row.map((cell: { title: string; value: ReactNode }, cellIndex) => (
                <QTableCell sx={{ verticalAlign: 'top', width: '50%', p: '14px 16px' }} key={cellIndex}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '12px',
                      alignItems: 'flex-start',
                    }}
                  >
                    <QTypography variant="bodySemiBold">{cell.title}</QTypography>

                    {cell.value}
                  </Box>
                </QTableCell>
              ))}
              {row.length === 1 && <QTableCell sx={{ verticalAlign: 'top', width: '50%', p: '14px 16px' }} />}
            </QTableRow>
          ))}
        </QTableBody>
      </QTable>
    </AccordionDetails>
  </Accordion>
);

export const AppMetadataPanel = ({
  isOpen,
  setIsOpen,
  appInfo,
  marketInfo,
  certificateInfo,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  appInfo: AppInfo;
  marketInfo: MarketInfo;
  certificateInfo: CertificateInfo;
}) => {
  const infoTables = [];

  if (!_.isEmpty(appInfo)) {
    infoTables.push({
      header: 'App Information',
      data: [
        [
          { title: 'Name', value: appInfo.name || 'Not specified' },
          { title: 'Version', value: appInfo.version || 'Not specified' },
        ],
        [
          { title: 'Version Code', value: appInfo.versionCode || 'Not specified' },
          { title: 'Min SDK', value: appInfo.minSdk || 'Not specified' },
        ],
        [
          { title: 'Target SDK', value: appInfo.targetSdk || 'Not specified' },
          { title: 'File Size', value: appInfo.fileSize || 'Not specified' },
        ],
        [
          {
            title: 'Submission Info',
            value: (
              <>
                {appInfo.submissionDate ? moment(appInfo.submissionDate).format('MM/DD/YYYY - h:mm:ss A') : 'Not specified'}
                <QChip label={appInfo.submissionTag || 'Not Specified'} variant="primaryDark" />
              </>
            ),
          },
        ],
        [{ title: 'SHA 1', value: appInfo.sha1 || 'Not specified' }],
        [{ title: 'MD5', value: appInfo.md5 || 'Not specified' }],
      ],
    });
  }

  if (!isEmpty(marketInfo)) {
    infoTables.push({
      header: 'Market Information',
      data: [
        [
          { title: 'Category', value: marketInfo.category || 'Not specified' },
          { title: 'Last Updated', value: marketInfo.lastUpdated ? moment(marketInfo.lastUpdated).format('MM/DD/YYYY') : 'Not specified' },
        ],
        [
          { title: 'Downloads', value: marketInfo.download || 'Not specified' },
          {
            title: 'Privacy Policy',
            value: marketInfo.privacyPolicy ? (
              <QLink href={marketInfo.privacyPolicy} target="_blank">
                <QTypography variant="bodySemiBold" color="inherit">
                  Website
                </QTypography>
              </QLink>
            ) : (
              'Not Specified'
            ),
          },
        ],
        [
          {
            title: 'Developer',
            value: (
              <>
                {marketInfo.developer || 'Not specified'}
                {marketInfo.developerEmail}
                {marketInfo.developerUrl && (
                  <QLink href={marketInfo.developerUrl} target="_blank">
                    <QTypography variant="bodySemiBold" color="inherit">
                      Website
                    </QTypography>
                  </QLink>
                )}
              </>
            ),
          },
        ],
      ],
    });
  }

  if (!isEmpty(certificateInfo)) {
    infoTables.push({
      header: 'Certificate Information',
      data: [
        [
          { title: 'Name', value: certificateInfo.name || 'Not specified' },
          { title: 'Minimum OS Version', value: certificateInfo.minimumOSversion || 'Not specified' },
        ],
        [
          { title: 'Platform', value: certificateInfo.platform || 'Not specified' },
          { title: 'Platform Version', value: certificateInfo.platformVersion || 'Not specified' },
        ],
        [
          { title: 'Developer', value: certificateInfo.developer || 'Not specified' },
          { title: 'Team ID', value: certificateInfo.teamId || 'Not specified' },
        ],
        [
          { title: 'Authority', value: certificateInfo.authority || 'Not specified' },
          {
            title: 'Application Identifier',
            value: certificateInfo.applicationIdentifier || 'Not specified',
          },
        ],
        [
          {
            title: 'Creation Date',
            value: certificateInfo.creationDate ? moment(certificateInfo.creationDate).format('MM/DD/YYYY') : 'Not specified',
          },
          {
            title: 'Expiration Date',
            value: certificateInfo.expirationDate ? moment(certificateInfo.expirationDate).format('MM/DD/YYYY') : 'Not specified',
          },
        ],
        [{ title: 'Compiler', value: certificateInfo.compiler || 'Not specified' }],
        [
          {
            title: 'Provisioning Profile App ID',
            value: certificateInfo.provisioningProfileAppId || 'Not specified',
          },
        ],
      ],
    });
  }

  return (
    <>
      {/* Drawer component for the left panel */}
      <Drawer anchor="right" open={isOpen} onClose={() => setIsOpen(false)}>
        <Box sx={{ width: '60vw', p: 2, height: '100%' }} role="presentation">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <QTypography variant="h4Bold">View Metadata</QTypography>
            <QIconButton onClick={() => setIsOpen(false)}>
              <Close />
            </QIconButton>
          </Box>
          <QDivider
            sx={{
              borderColor: theme.palette.greyOverride[400],
              marginTop: 2,
              marginBottom: 2,
            }}
          />

          {infoTables.map((info, index) => (
            <InfoTable key={index} header={info.header} data={info.data} />
          ))}
        </Box>
      </Drawer>
    </>
  );
};
