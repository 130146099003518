import _ from 'lodash';
import { Box, Link } from '@mui/material';

import { QDivider } from '../../components/QDivider/QDivider';
import { QBadge } from '../../components/QBadge/QBadge';
import { QTypography } from '../../components/QTypography/QTypography';

export const Regulations = ({ details = {} }: any) => {
  function isNotEmpty(regulations) {
    return regulations && !_.isEmpty(regulations);
  }

  function ReviewStatusTag({ reg = {} }: any) {
    const detailsFound = details.found;
    return detailsFound && reg.fail ? (
      <QBadge
        variant="red"
        label={
          <QTypography variant="smallRegular" style={{ color: '#000000' }}>
            Fail
          </QTypography>
        }
        sx={{ backgroundColor: '#FF8A65', minWidth: '72px', height: '24px' }}
      />
    ) : (
      <QBadge
        variant="warning"
        label={
          <QTypography variant="smallRegular" style={{ color: '#000000' }}>
            Review
          </QTypography>
        }
        sx={{ minWidth: '72px', height: '24px' }}
      />
    );
  }

  function RegulationsSection({ regulations = [], title = null }: any) {
    return isNotEmpty(regulations) ? (
      <Box
        display="flex"
        flexDirection="column"
        style={{
          marginLeft: '16px',
          marginBottom: '12px',
        }}
      >
        <QTypography
          variant="h6Bold"
          style={{
            color: '#000000',
          }}
        >
          {title}
        </QTypography>
        {regulations.map((reg, index) => (
          <Box display="flex" flexDirection="row" columnGap="12px" key={index} style={{ marginTop: '12px' }}>
            <ReviewStatusTag reg={reg} />
            <Link href={reg.link} underline="hover">
              {reg.item}
            </Link>
          </Box>
        ))}
      </Box>
    ) : (
      <></>
    );
  }

  return (
    <>
      <RegulationsSection regulations={details.owasp} title="OWASP" />
      {isNotEmpty(details.owasp) && isNotEmpty(details.niap) && (
        <QDivider
          style={{
            borderColor: '#E0E0E0',
            marginTop: '14px',
            marginBottom: '14px',
          }}
        />
      )}
      <RegulationsSection regulations={details.niap} title="NIAP" />
      {isNotEmpty(details.gdpr) && (isNotEmpty(details.niap) || isNotEmpty(details.owasp)) && (
        <QDivider
          style={{
            borderColor: '#E0E0E0',
            marginTop: '14px',
            marginBottom: '14px',
          }}
        />
      )}
      <RegulationsSection regulations={details.gdpr} title="GDPR" />
    </>
  );
};
